import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    // TODO: Testar quando tiver ambiente de desenvolvimento e nas aplicações
    // Define um cookie com as opções fornecidas.
    set(
        name: string,
        value: string,
        expires?: Date, // data de expiração do cookie (opcional)
        path: string = '/',
        domain: string = '', // '.app.net' - O cookie será acessível por todos os subdomínios sob app.net.
        secure: boolean = false, // O cookie só será enviado sobre HTTPS
        httpOnly: boolean = true, // O cookie não será acessível por JavaScript.
        sameSite: 'Lax' | 'Strict' | 'None' = 'Lax'
    ) {
        let cookieOptions = `${name}=${encodeURIComponent(
            value
        )}; path=${path}; SameSite=${sameSite}`;

        if (expires) {
            cookieOptions += `; expires=${expires.toUTCString()}`;
        }
        if (domain) {
            cookieOptions += `; domain=${domain}`;
        }
        if (secure) {
            cookieOptions += '; secure';
        }
        if (httpOnly) {
            cookieOptions += '; HttpOnly';
        }
        this.document.cookie = cookieOptions;
    }

    // Obtém o valor do cookie pelo nome.
    get(name: string): string {
        const cookies = this.document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return decodeURIComponent(cookie.substring(name.length + 1));
            }
        }
        return '';
    }

    // Remove o cookie pelo nome.
    delete(name: string, path: string = '/', domain: string = '') {
        const deletionOptions = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`;
        if (domain) {
            this.document.cookie = `${deletionOptions}; domain=${domain}`;
        } else {
            this.document.cookie = deletionOptions;
        }
    }
}
