export const environment = {
    production: false,
    URL_BASE: 'https://appdev.tvsbt.com.br/suite',
    URL_LOGOUT: 'https://appdev.tvsbt.com.br/suite',
    URL_LOGIN: 'https://appdev.tvsbt.com.br/suite',
    PORT_LOGIN: '/login-externo',
    PORT_CORE: '',
    PATH: '/portalfreelancer',
    MODE: 'dev',
    LOCAL_STORAGE_KEY: '16b350b6e1b3e7f4aa24ebf1e57a1eab',
    LOCAL_STORAGE_IV: '0000000000000000',
    APP: 'freelancer',
    RESOURCE_ACCESS: 'sbt-portalfreelancer',
    RECAPTCHA_KEY: '6LfGI2EpAAAAADfYiZgMhlJsJjXry8QJHaIC0zxl'
};
