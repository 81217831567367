import { Inject, Injectable } from '@angular/core';
import { PERMISSOES_SERVICE_TOKEN, PermissoesService } from '@sbt-suite/components';

@Injectable()
export class PermissoesFactory {
    constructor(@Inject(PERMISSOES_SERVICE_TOKEN) private permissoesService: PermissoesService) {}

    canActivate(role: string): boolean {
        return this.permissoesService.checkPermissao(role);
    }
}
