import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    AUTH_INTERCEPTOR_CONFIG,
    AuthInterceptorConfig,
    LocalStorageService
} from '@sbt-suite/components';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    isGetToken: boolean = false;
    appTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private _router: Router,
        @Inject(LocalStorageService) private _localStorage: LocalStorageService,
        @Inject(AUTH_INTERCEPTOR_CONFIG) private config: AuthInterceptorConfig
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = this._localStorage.getItem('access_token');
        const isUrlPublic = this._isPublicUrl(request);

        // se não tiver token e a rota não for pública redireciona de volta para o login
        if (!token && !isUrlPublic) {
            this._router.navigateByUrl('/login');
            return EMPTY;
        }

        // caso tenho o token e a api não seja a chamada autenticada
        if (isUrlPublic) {
            return next.handle(request);
        }

        const req = this._addTokenToHeader(request, token);
        return next.handle(req);
    }

    private _addTokenToHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
        const _req = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
        return _req;
    }

    private _isPublicUrl(request: HttpRequest<unknown>): Boolean {
        const isStepLogin = request.url.includes('/api/v1/login/step');
        const isLogin = request.url.includes('/api/v1/login');
        const isMfa = request.url.includes('/api/v1/mfa');
        const isRecuperarSenha = request.url.includes('/senha/freelancer');
        const isViaCep = request.url.includes('viacep');

        return isStepLogin || isLogin || isMfa || isRecuperarSenha || isViaCep;
    }
}
