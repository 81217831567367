import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService, getHeadersFormUrlEncoded } from '@sbt-suite/components';
import { lastValueFrom } from 'rxjs';
import { buildUrl } from 'src/app/shared/functions';
import { environment } from 'src/environments/environment';
import { ICredenciaisUsuario } from '../../models';
import { RsaService } from '../rsa/rsa.service';
import { IAplicacaoAPI } from './../../models/usuario.model';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {
    URL_BASE = environment.URL_BASE;
    PORT_LOGIN = environment.PORT_LOGIN;

    constructor(
        private http: HttpClient,
        private rsa: RsaService,
        private localStorageService: LocalStorageService
    ) {}

    async redefinirSenha(credenciais: ICredenciaisUsuario, token: string, recaptcha: string) {
        const { nonceToken, senhaEncriptada } = await this.rsa.gerarNonceTokenESenha(credenciais);

        return lastValueFrom(
            this.http.put(
                buildUrl(
                    this.URL_BASE,
                    `/login-externo/api/v1/usuario/${credenciais.email}/senha/${environment.APP}`,
                    this.PORT_LOGIN
                ),
                {
                    nonceToken: nonceToken,
                    senhaEncriptada: senhaEncriptada,
                    token: token,
                    recaptcha
                }
            )
        );
    }

    async recuperarSenha(email: string) {
        const params = new HttpParams().set('email', email);
        return lastValueFrom(
            this.http.post(
                buildUrl(
                    this.URL_BASE,
                    `/login-externo/api/v1/usuario/${email}/senha/${environment.APP}`,
                    this.PORT_LOGIN
                ),
                params.toString(),
                {
                    headers: getHeadersFormUrlEncoded()
                }
            )
        );
    }

    async listarAplicacoes(): Promise<IAplicacaoAPI[]> {
        return await lastValueFrom(
            this.http.get<IAplicacaoAPI[]>(
                buildUrl(this.URL_BASE, '/login-externo/api/v1/aplicacoes', this.PORT_LOGIN)
            )
        );
    }
}
