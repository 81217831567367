import { HttpClient, HttpParams } from '@angular/common/http';
import { getHeadersFormUrlEncoded } from '@sbt-suite/components';
import { lastValueFrom } from 'rxjs';
import { buildUrl } from 'src/app/shared/functions';
import { environment } from 'src/environments/environment';
import { RsaService } from '..';
import { APLICACAO, GRANT_TYPE_PASSWORD } from '../../constants/autenticacao';
import { IAutenticacaoService, ICredenciaisUsuario, IResponseAuthLogin } from '../../models';

export class UsuarioExternoAutenticacaoService implements IAutenticacaoService {
    URL_BASE = environment.URL_BASE;

    constructor(private http: HttpClient, private rsa: RsaService) {}

    async login(params: any): Promise<void> {
        await this._loginExterno(params);
    }

    logout(): Promise<any> {
        return lastValueFrom(this.http.post(buildUrl(this.URL_BASE, '/api/v1/logout', ''), {}));
    }

    private async _loginExterno(credenciais: ICredenciaisUsuario): Promise<IResponseAuthLogin> {
        const { nonceToken, senhaEncriptada } = await this.rsa.gerarNonceTokenESenha(credenciais);

        const params = new HttpParams()
            .set('aplicacao', APLICACAO)
            .set('username', credenciais.email)
            .set('password', senhaEncriptada)
            .set('nonceToken', nonceToken)
            .set('grant_type', GRANT_TYPE_PASSWORD)
            .set('recaptcha', credenciais.recaptcha as string);

        return lastValueFrom(
            this.http.post<IResponseAuthLogin>(
                buildUrl(this.URL_BASE, '/login-externo/api/v1/login', ''),
                params.toString(),
                {
                    headers: getHeadersFormUrlEncoded()
                }
            )
        );
    }
}
