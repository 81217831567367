import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AutenticacaoService } from '../services';
import { PermissoesFactory } from './permissoes-factory';

export const permissaoRotaGuard: CanActivateFn = (route) => {
    const permissoesFactory = inject(PermissoesFactory);
    const autenticacaoService = inject(AutenticacaoService);
    const routeRoles = route.data['roles'] as string[];
    const router = inject(Router);

    if (!autenticacaoService.checkAcessToken()) {
        router.navigate(['/login']);
        return false;
    }

    if (!routeRoles || routeRoles?.length === 0) {
        return true; // Se não houver roles definidas, permite o acesso
    }

    // Verifica se o usuário tem pelo menos uma das roles necessárias
    const temPermissao = routeRoles.some((role) => permissoesFactory.canActivate(role));

    if (!temPermissao) {
        router.navigate(['/nao-autorizado']);
        return false;
    }

    return true;
};
